<template>
  <div class="mb-12">
    <div class="flex justify-start items-end">
      <div class="md:w-6/12"></div>
      <div class="md:w-5/12">
        <HeaderGlobal
          :titlemenu1="$t('pet_record_title')"
          :to1="'/petoverview/new'"
          :titlemenu2="$t('pet_record_cat_new_title')"
          class="pl-4"
        />
      </div>
    </div>
    <div class="mt-12 flex justify-center items-center flex-col">
      <div>
        <img :src="url" class="rounded-full w-24 h-24 object-cover" />
      </div>
      <div class="my-2 flex items-center justify-center cursor-pointer">
        <input
          type="file"
          @change="onFileChange"
          id="upload-photo"
          class="-z-1 opacity-0 absolute cursor-pointer"
        />

        <label
          for="upload-photo"
          class="mt-2 font-proximaMedium text-mediumGrey text-xs text-center cursor-pointer"
          >{{ $t("global_add_profile_picture") }}</label
        >
      </div>
    </div>
    <CatForms @changename="petImage = $event" :url="url" :file="file" />
  </div>
</template>

<script>
import HeaderGlobal from "../../header/HeaderGlobal.vue";
import InputImage from "../../forms/InputImage.vue";
import CatForms from "./CatForms.vue";
import image from "@/assets/images/peticons/cat_icon.png";

export default {
  components: {
    HeaderGlobal,
    InputImage,
    CatForms,
  },
  data() {
    return {
      petImage: image,
      url: image,
      file: null,
    };
  },
  methods: {
    onFileChange(e) {
      const file = e.target.files[0];
      this.file = file;
      this.url = URL.createObjectURL(file);

      this.$emit("blurred", file);
    },
  },
};
</script>

<style>
</style>
