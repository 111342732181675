<template>
  <div class="my-6">
    <CatSection />
    <NavMenuSecondary  />
  </div>
</template>

<script>
import CatSection from "../components/newpetrecord/cat/CatSection.vue";
import NavMenuSecondary from "../components/navigationmenu/secondary/NavMenuSecondary.vue"

export default {
  components: {
    CatSection,
    NavMenuSecondary
  },
};
</script>